import l from '../images/logo.png'
import './estilos/footer.css'
import React, {  useEffect } from 'react';
import { NavLink } from 'react-router-dom';
export const Footer = () => {

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
      const handleScroll = () => {
        const scrollButton = document.getElementById("scrollButton");
        if (window.pageYOffset > 600) {
          scrollButton.style.display = "block";
        } else {
          scrollButton.style.display = "none";
        }
      };
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };

  return (
    <>
        <footer>
        <div className="footer-1">
            <div className="txt1-footer-1">
                <img className="logo-footer" src={l} alt="logo"/>
                <h1 className="ti2-1">CONSULTINSEL S.R.L</h1>
                <p className="ti2-inter">Consultora Ambiental</p>
            </div>

            <div className="redes">
                <a href="https://www.facebook.com/consultingsel/" target="_blank" rel="noopener noreferrer" className="bi bi-facebook" aria-label="facebook"></a>
                <a href="https://www.instagram.com/consultingsel/" target="_blank" rel="noopener noreferrer" className="bi bi-instagram" aria-label="instagram"></a>
                <a href="https://www.tiktok.com/@consultingsel?_t=8qBfRwhbsRS&_r=1" target="_blank" rel="noopener noreferrer" className="bi bi-tiktok" aria-label="Go to top"></a> 
                <a href="https://www.youtube.com/@consulting-sel" target="_blank" rel="noopener noreferrer" className="bi bi-youtube" aria-label="Go to top"></a> 
            </div>
        </div>
        <div className="footer-4">
        <div id="contacto">
            <h2>Contacto</h2>
            <p><i className="bi bi-telephone"></i>974552598 / 956815233</p>
            <a href="mailto:consultingseladm@gmail.com"><i className="bi bi-envelope"></i>consultingseladm@gmail.com</a>
            <a href="mailto:luisroblesarana@consulting-sel.com"><i className="bi bi-envelope"></i>luisroblesarana@consulting-sel.com</a>
            {/* <a href="mailto:luisroblesarana@consulting-sel.com"><i className="bi bi-envelope"></i>kiaramiranda@consulting-sel.com</a> */}
          </div>
        <div className="---">
            <h1 className="ti1-">Horario de atención</h1>
              <div className="pa1">
                <p className="p1">Lunes - Domingo</p>
                <p>8:00 am - 6:00 pm</p>
              </div>
          </div>
          
        </div>
        <iframe id="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3869.904432386433!2d-75.7450753251377!3d-14.082818486344404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9110e3fcf56cb3fb%3A0x80be638e73d5ada6!2sConsultingsel%20S.R.L.!5e0!3m2!1ses!2spe!4v1707822249510!5m2!1ses!2spe" 
            width='100%' 
            height='220px'
            allowFullScreen 
            title="Google Maps"
            style={{
              padding:'20px 0',
              border: '0',
              loading: 'lazy',
              referrerPolicy: 'no-referrer-when-downgrade'
            }}
          ></iframe>
    </footer>
    <div className="end">
        <h1 className='txt-end'>© 2024 CSL - Todos los derechos reservados | By <b>Junior Mendoza</b></h1>
    </div>
    <div className="flotante">
      <a className='a_svg' href="https://documentacion.consulting-sel.com/public/index.php" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 -2 16 20">
          <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"/>
          <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"/>
        </svg>
        <div className='hover_info_icon_late'>
            <p>Control</p>
          </div>
      </a>
      <a className='a_svg' href="https://wa.me/51974552598?text=Hola, deseo mas informacion sobre tus servicios" target="_blank" rel="noopener noreferrer"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-whatsapp" viewBox="0 -2 16 20">
          <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
          </svg>
          <div className='hover_info_icon_late'>
            <p>Escribenos</p>
          </div>
      </a>
      <NavLink to="/mensajeria" className={'a_svg'}>
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-chat-dots" viewBox="0 -2 16 20" >
          <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
          <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
        </svg>
        <div className='hover_info_icon_late'>
            <p>Mensajería</p>
        </div>
      </NavLink>
      <p>
        <svg onClick={scrollToTop} xmlns="http://www.w3.org/2000/svg" width="30" height="30" translate="no" fill="currentColor" className="bi bi-chevron-right" id="scrollButton" viewBox="0 -2 16 20" >
          <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
        </svg>
      </p>
    </div>
    </>
  );
}
